export const LoginUserIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '16'}
      height={props.height || '18.963'}
      viewBox="0 0 24.02 24.49"
    >
      <g id="user-10" transform="translate(-23.819 -26.9)">
        <path
          id="Path_132898"
          data-name="Path 132898"
          d="M54.666,33.833a6.833,6.833,0,1,0-6.833,6.833A6.834,6.834,0,0,0,54.666,33.833Zm-11.883,0a5.05,5.05,0,1,1,5.05,5.05A5.046,5.046,0,0,1,42.782,33.833Z"
          transform="translate(-12.005)"
          stroke="#000"
          strokeWidth="0.2"
          fill={props.color}
        />
        <path
          id="Path_132899"
          data-name="Path 132899"
          d="M24.39,89.081a.838.838,0,0,0,.446.119.861.861,0,0,0,.772-.446,11.756,11.756,0,0,1,20.469,0,.892.892,0,0,0,1.545-.891,13.566,13.566,0,0,0-23.588,0A.893.893,0,0,0,24.39,89.081Z"
          transform="translate(0 -37.958)"
          stroke="#000"
          strokeWidth="0.2"
          fill={props.color}
        />
      </g>
    </svg>
  );
};
