export const WhatsappIcon = (props) => {
  return (
    <svg
      id="whatsapp"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '15.228'}
      height={props.height || '15.603'}
      viewBox="0 0 25 25"
    >
      <path
        id="Path_50043"
        data-name="Path 50043"
        d="M21.375,3.593A12.648,12.648,0,0,0,12.547,0,12.409,12.409,0,0,0,1.767,18.581L0,25l6.6-1.721a13.086,13.086,0,0,0,5.954,1.507A12.406,12.406,0,0,0,21.359,3.633Z"
        transform="translate(0)"
        fill="#fff"
      />
      <path
        id="Path_50044"
        data-name="Path 50044"
        d="M12.481,22.575h-.023a10.5,10.5,0,0,1-5.641-1.66L2.911,21.93l1.047-3.8-.249-.391A10.28,10.28,0,0,1,2.13,12.263C2.13,3.1,13.323-1.477,19.8,5A10.305,10.305,0,0,1,12.481,22.575Z"
        transform="translate(0.089 0.082)"
        fill="#4caf50"
      />
      <path
        id="Path_50045"
        data-name="Path 50045"
        d="M17.987,14.638l-.009.078c-.314-.156-1.841-.9-2.125-1.006-.639-.236-.458-.037-1.684,1.367-.182.2-.364.219-.673.078a8.454,8.454,0,0,1-2.5-1.547,9.451,9.451,0,0,1-1.729-2.156c-.305-.527.333-.6.915-1.7a.573.573,0,0,0-.026-.546c-.078-.156-.7-1.688-.96-2.3s-.507-.531-.7-.531a1.55,1.55,0,0,0-1.425.358c-1.681,1.848-1.257,3.754.181,5.781,2.827,3.7,4.333,4.381,7.087,5.327a4.31,4.31,0,0,0,1.958.126,3.206,3.206,0,0,0,2.1-1.485A2.578,2.578,0,0,0,18.581,15c-.077-.141-.281-.219-.594-.359Z"
        transform="translate(0.249 0.265)"
        fill="#fff"
      />
    </svg>
  );
};
