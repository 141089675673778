import { getCustomHeaderTextColor } from './getCustomHeaderTextColor';

/**
 * invert classes are applied on angle icon based on the headerBgColor
 * @param {*} headerBgColor
 * @return {String} a class based on headerBgColor
 */
export function invertAngleRightIconColor(headerBgColor) {
  return getCustomHeaderTextColor(headerBgColor) === '#ffffff'
    ? 'filter-invert-1'
    : 'filter-invert-0';
}
