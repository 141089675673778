import { useSSRSelector } from '@/redux/ssrStore';
import { redirectToHomePage } from '@/utils/handleRedirectToHomePage';
import { getVisibilityForHeader } from '@/utils/logoAndNameHelpers';
import { PATH } from '@/utils/routes';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';

export const StoreNameComp = ({ customClass = '', showLogo = false }) => {
  const storeData = useSSRSelector((state) => state.storeReducer.store);
  const { store_info: storeInfo, theme } = storeData;

  const storeLogo = getActualOrDsStoreLogo(storeData);

  return (
    <div className="name-logo-container">
      {storeLogo && getVisibilityForHeader(theme?.store_logo_status) && showLogo && (
        <img
          className="store-logo pointer"
          src={storeLogo}
          alt="store logo"
          onClick={() => redirectToHomePage(PATH.PRODUCT, storeInfo?.domain)}
        />
      )}
      {getVisibilityForHeader(theme?.store_name_status) && (
        <span
          className={`store-name flex items-center pointer ${
            customClass ? customClass : ''
          }`}
          title={storeInfo?.name}
        >
          <span onClick={() => redirectToHomePage(PATH.PRODUCT, storeInfo?.domain)}>
            {' '}
            {storeInfo?.name}
          </span>
        </span>
      )}
    </div>
  );
};
