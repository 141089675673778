export const FilledShoppingCart = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '15.228'}
      height={props.height || '15.603'}
      viewBox="0 0 25.109 22"
    >
      <g id="shopping-cart" transform="translate(0 -31.001)">
        <circle
          id="Ellipse_5152"
          data-name="Ellipse 5152"
          cx="2"
          cy="2"
          r="2"
          transform="translate(8.769 49.001)"
          fill={props.color}
        />
        <circle
          id="Ellipse_5153"
          data-name="Ellipse 5153"
          cx="2"
          cy="2"
          r="2"
          transform="translate(16.769 49.001)"
          fill={props.color}
        />
        <path
          id="Path_229354"
          data-name="Path 229354"
          d="M.736,32.5H3.8L7.355,45.174l-.279.568A2.248,2.248,0,0,0,9.05,49H21.431a.75.75,0,0,0,0-1.5H9.05a.749.749,0,0,1-.658-1.085L8.6,46H21.431a.738.738,0,0,0,.708-.544l2.942-10.5a.764.764,0,0,0-.12-.658.733.733,0,0,0-.588-.3H5.754l-.688-2.455A.738.738,0,0,0,4.358,31H.736a.75.75,0,0,0,0,1.5Z"
          transform="translate(0 0)"
          fill={props.color}
        />
      </g>
    </svg>
  );
};
