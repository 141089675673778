export const FilledUserIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '15.228'}
      height={props.height || '15.603'}
      viewBox="0 0 22.378 25"
    >
      <g id="user" transform="translate(-24.165)">
        <g id="Group_132777" data-name="Group 132777" transform="translate(28.865)">
          <g id="Group_132776" data-name="Group 132776">
            <path
              id="Path_229346"
              data-name="Path 229346"
              d="M117.282,0a6.491,6.491,0,1,0,6.491,6.491A6.508,6.508,0,0,0,117.282,0Z"
              transform="translate(-110.791)"
              fill={props.color}
            />
          </g>
        </g>
        <g
          id="Group_132779"
          data-name="Group 132779"
          transform="translate(24.165 13.446)"
        >
          <g id="Group_132778" data-name="Group 132778">
            <path
              id="Path_229347"
              data-name="Path 229347"
              d="M46.495,252.563a6.05,6.05,0,0,0-.652-1.19,8.068,8.068,0,0,0-5.584-3.515,1.2,1.2,0,0,0-.822.2,6.919,6.919,0,0,1-8.163,0,1.063,1.063,0,0,0-.822-.2,8.009,8.009,0,0,0-5.584,3.515,6.974,6.974,0,0,0-.652,1.19.6.6,0,0,0,.028.539,11.313,11.313,0,0,0,.765,1.134,10.773,10.773,0,0,0,1.3,1.474,16.987,16.987,0,0,0,1.3,1.134,12.926,12.926,0,0,0,15.419,0,12.46,12.46,0,0,0,1.3-1.134,13.093,13.093,0,0,0,1.3-1.474,9.948,9.948,0,0,0,.765-1.134A.484.484,0,0,0,46.495,252.563Z"
              transform="translate(-24.165 -247.841)"
              fill={props.color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
